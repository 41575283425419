import React from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"

const FaqListSideBarWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const FaqSideList = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Gotham HTF Book";
  margin: 0px;
  position: relative;
  width: 100%;

  > a.active {
    background: #eaeaea;
    opacity: 1;
  }
`

const FaqSideListTitleLink = styled(Link)`
  font-family: "Gotham HTF Book";
  margin: 0px 0px 5px 0px;
  position: relative;
  padding: 10px;
  background: transparent;
  opacity: 0.5;

  :hover {
    background: #eaeaea;
    opacity: 1;
  }
`

const FaqSideListTitle = styled.span`
  font-family: "Gotham HTF Book";
`

const FaqListSideBar = ({ intl, data, title, sidebarKey }) => {
  return (
    data && (
      <FaqListSideBarWrapper>
        {data.map((item, index) => {
          return (
            item &&
            item.key === sidebarKey && (
              <FaqSideList key={index.toString()}>
                {item &&
                  item.item_list &&
                  item.item_list.map((list_item, item_index) => {
                    return (
                      list_item &&
                      list_item.item_title && (
                        <FaqSideListTitleLink
                          to={list_item.item_link}
                          key={item_index.toString()}
                          className={
                            title && list_item.item_title === title && "active"
                          }
                        >
                          <FaqSideListTitle
                            dangerouslySetInnerHTML={{
                              __html: list_item.item_title,
                            }}
                          />
                        </FaqSideListTitleLink>
                      )
                    )
                  })}
              </FaqSideList>
            )
          )
        })}
      </FaqListSideBarWrapper>
    )
  )
}

export default injectIntl(FaqListSideBar)
