import React, { Fragment } from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"

import {
  SectionContainer,
  FaqSectionWrapper,
  FaqWrapper,
  FaqLeftDiv,
  FaqRightDiv,
  FaqTextBox,
  FaqCategoryListDiv,
  FaqCategoryBoxWrapper,
  FaqCategoryBox,
  FaqCategoryBoxTitle,
} from "../../../../components/Layout/styled"

import Footer from "../../../../components/Layout/Footer"
import SeoComp from "../../../../components/SeoComp"
import FaqListSideBar from "../../../../components/FaqListSideBar"
import FaqListBreadCrumbSection from "../../../../components/FaqListBreadCrumbSection"
import FaqListPrevNextSection from "../../../../components/FaqListPrevNextSection"
import FaqSearchSection from "../../../../components/Layout/FaqSearchSection";

const FaqSubPage = ({ intl, data }) => {
  const faqPageUrl = (pageTitle, catName) => {
    var faqList = ""
    var newList1 = ""
    var newList2 = ""
    var pageUrl = ""
    faqList =
      data.allDirectusFaqPageTranslation.nodes[0].faq_product_category &&
      data.allDirectusFaqPageTranslation.nodes[0].faq_product_category.list
    newList1 = faqList.find(list => list.key === catName)
    if (newList1) {
      newList2 = newList1.item_list.find(list => list.item_title === pageTitle)
      pageUrl = newList2.item_link
    }
    return pageUrl
  }

  const getTitle = key => {
    var faqList = ""
    var pageTitle = ""
    faqList =
      data.allDirectusFaqPageTranslation.nodes[0].faq_product_category &&
      data.allDirectusFaqPageTranslation.nodes[0].faq_product_category.list
    pageTitle = faqList.find(list => list.key === key).title
    return pageTitle
  }

  return (
    data &&
    data.allDirectusFaqPageTranslation.nodes.length > 0 &&
    data.allDirectusFaqListTranslation.nodes.length > 0 && (
      <Fragment>
        <SeoComp
          description={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].description
          }
          title={
            data.allDirectusFaqPageTranslation.nodes[0] &&
            data.allDirectusFaqPageTranslation.nodes[0].faq_product_category &&
            data.allDirectusFaqPageTranslation.nodes[0].faq_product_category
              .list &&
            data.allDirectusFaqPageTranslation.nodes[0].faq_product_category.list.find(
              item => item.key === "loop"
            ).title
          }
          twitter_card={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].twitter_card
          }
          image={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].image &&
            data.allDirectusSeoTranslation.nodes[0].image.data &&
            data.allDirectusSeoTranslation.nodes[0].image.data.url
          }
          hreflangPathname={{
            fr:
              "faq/informations-et-conseils-sur-nos-produits/je-souhaite-avoir-des-informations-sur-loop",
            en: "",
            de: "",
            es: "",
          }}
        />
        <SectionContainer>
          <FaqSearchSection />
          <FaqSectionWrapper>
            {data.allDirectusFaqPageTranslation.nodes[0].faq_list &&
              data.allDirectusFaqPageTranslation.nodes[0].faq_list.list && (
                <FaqListBreadCrumbSection
                  data={data.allDirectusFaqPageTranslation.nodes[0]}
                  title={getTitle("loop")}
                  sidebarKey={"information_and_tips_on_our_products"}
                />
              )}

            {data.allDirectusFaqPageTranslation.nodes[0].faq_list &&
              data.allDirectusFaqPageTranslation.nodes[0].faq_list.list && (
                <FaqListPrevNextSection
                  data={
                    data.allDirectusFaqPageTranslation.nodes[0].faq_list.list
                  }
                  title={getTitle("loop")}
                  sidebarKey={"information_and_tips_on_our_products"}
                />
              )}

            <FaqWrapper>
              <FaqLeftDiv>
                {data.allDirectusFaqPageTranslation.nodes[0].faq_list &&
                  data.allDirectusFaqPageTranslation.nodes[0].faq_list.list && (
                    <FaqListSideBar
                      data={
                        data.allDirectusFaqPageTranslation.nodes[0].faq_list
                          .list
                      }
                      title={getTitle("loop")}
                      sidebarKey={"information_and_tips_on_our_products"}
                    />
                  )}
              </FaqLeftDiv>

              <FaqRightDiv>
                <FaqCategoryListDiv>
                  {data.allDirectusFaqListTranslation.nodes &&
                    data.allDirectusFaqListTranslation.nodes.map(
                      (item, index) => {
                        return (
                          item && (
                            <FaqCategoryBoxWrapper key={index.toString()}>
                              <FaqCategoryBox
                                to={faqPageUrl(item.title, "loop")}
                              >
                                <FaqCategoryBoxTitle
                                  dangerouslySetInnerHTML={{
                                    __html: item.title,
                                  }}
                                />
                                {/* <FaqTextBox
                                  dangerouslySetInnerHTML={{
                                    __html: item.content,
                                  }}
                                /> */}
                              </FaqCategoryBox>
                            </FaqCategoryBoxWrapper>
                          )
                        )
                      }
                    )}
                </FaqCategoryListDiv>
              </FaqRightDiv>
            </FaqWrapper>
          </FaqSectionWrapper>
        </SectionContainer>

        {data.allDirectusFooterTranslation.nodes.length > 0 && (
          <Footer data={data.allDirectusFooterTranslation.nodes[0]} />
        )}
      </Fragment>
    )
  )
}

export const query = graphql`
  query($lang: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allDirectusFaqPageTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        faq_page_title
        faq_search_title
        faq_article_useful_title
        faq_feedback_success
        faq_list {
          list {
            title
            link
            key
            icon
            item_list {
              item_title
              item_link
            }
          }
        }
        faq_product_category {
          list {
            title
            link
            key
            item_list {
              item_title
              item_link
            }
          }
        }
      }
    }
    allDirectusFaqListTranslation(
      filter: {
        faq_list: {
          faq_category: { eq: "information_and_tips_on_our_products" }
          faq_product_category: { eq: "loop" }
        }
        language: { eq: $lang }
      }
    ) {
      nodes {
        title
        content
      }
    }
    allDirectusFooterTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        facebook
        gbp_facebook
        youtube
        twitter
        instagram
        linkedin
        about_us {
          name
          list {
            link
            list
          }
        }
        contact {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        purchases {
          list {
            list
            link
          }
          name
        }
        help {
          list {
            link
            list
          }
          name
        }
        about_us_menu {
          name
          list {
            link
            list
          }
        }
        knowledge_center_menu {
          name
          list {
            link
            list
          }
        }
        shop_menu {
          name
          list {
            link
            list
          }
        }
        support_menu {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        newsletter_heading
        follow_us_heading
        footer_bottom {
          list {
            link
            list
          }
        }
      }
    }
    allDirectusSeoTranslation(
      filter: { language: { eq: $lang }, seo: { page_name: { eq: "faq" } } }
    ) {
      nodes {
        description
        title
        twitter_card
        twitter_title
        url
        image {
          data {
            url
          }
        }
      }
    }
  }
`

export default injectIntl(FaqSubPage)
